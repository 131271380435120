/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/
table.subscriptions-list {
  margin: 50px 0 20px 0;
  tr {
    vertical-align: middle;
    padding: 20px 0;
    &:not(:last-child) {
      border-bottom: 1px dotted #eee;
    }
    &:hover {
      background: #eee;
    }
    td {
      padding: 15px 5px;
    }
  }
  .prices {
    strong {
      font-size: 1.5em;
    }
    span {
      font-size: .8em;
    }
  }
}

.feature.glyphicon {
  font-size: 1.3em;
  &.glyphicon-remove {
    color: #ccc;
  }
  &.glyphicon-ok {
    color: @brand-primary;
  }
}

.billing-period {
  padding: 40px 0 30px;
  span {
    color: @text-color;
    padding: 5px 20px;
  }
  button {
    &:active:focus,&.active  {
      background-color: @brand-primary!important;
      span {
        color: white;
      }
    }
    &:focus{
      outline: none!important;
    }
  }
}

.free-subscription-button {
  width: 48%;
  margin: 0 1%;
}

.description-panel {
  margin: 0 -5px 15px;
  min-height: 660px;
  border-style: dotted;
  > .panel-body {
    padding: 0;
  }
  .list-group-item {
    font-size: .9em;
    border: none;
    &:hover {
      background-color: #eee;
    }
  }
  h3 {
    min-height: 90px;
    padding: 20px 15px 10px;
    font-size: 1.5em;
    font-weight: 600;
    background: linear-gradient(to bottom, #eee, #fff);
    margin: 0;
  }
  &:hover {
    .box-shadow(0 3px 5px rgba(0,0,0,.3));
  }
}

.warnings-free-user {
  padding: 70px 0 0;
}

.warnings-free-user, .warnings-footer {
  font-size: .8em;
  color: #999;
}

.warnings-footer {
  padding: 70px 0 40px;
}
span.badge {
  position: relative;
  top: -5px!important;
  background-color: rgba(0,0,0,.2)!important;
  font-size: .7em;
  padding: 3px 5px;
}

.page-header {
  margin-top: -20px;
  margin-bottom: 20px;
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .description-panel {
    min-height: 680px;
  }
}