/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

@import "./w2mo-bootstrap";

.navbar-default {
  .layout-name {
    font-size: 1.4em;
    margin: 15px;
  }
  .navbar-brand {
    padding: 5px;
    .lv-logo {
      height: 100%;
      width: 140px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../common/images/logivations-logo-no-map-color.png");
      & when (@navbar-default-color = white) {
        background-image: url("../../common/images/logivations-logo-no-map.png");
      }
    }
  }
}