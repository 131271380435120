/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

@import "./w2mo-fonts";

/*
 Languages Dropdown Style
 @Ref: projects/w2mo-front-end/src/frontend/modules/common/react-extension/NavLanguageDropdown.tsx
 */
.languages-dropdown-menu {

  &.active > .dropdown-toggle {
    background-color: transparent !important;
    color: rgba(256, 256, 256, .6)!important;
  }

  .language-menu-item > a[role=menuitem] {
    color: #666
  }
}

/*
 Languages Header style
 @Ref: projects/w2mo-front-end/src/frontend/modules/common/react-extension/header/index.ts
 */
.w2mo-page-header {
  .page-title {
    //color: @brand-primary;
  }
}

/*
  Styles for notifications
  @ref: projects/w2mo-front-end/src/frontend/modules/common/redux/notifications/index.ts
 */
.notifications-container {

}


/*
 Languages Dropdown Style
 @Ref: projects/w2mo-front-end/src/frontend/modules/common/redux/loader
 */
.loader-container {
  text-align: center;
  .fa-spinner {
  }
}

input:focus,
button:focus {
  outline: none !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance:none;
  appearance: none;
  font-size: 0;
  line-height: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #666666;
  background: white;
  position: relative;
  transition: all 0.2s;
  border-radius: 2px;
}



input[type="checkbox"]:checked {
  background-color: #8DBF4C;
  border-color: #8DBF4C;
}

input[type="checkbox"]:checked:before {
  position: absolute;
  content: '';
  right: 4px;
  bottom: 2px;
  height: 9px;
  border-left: 2px solid white;
  transform: rotate(40deg);
  border-radius: 2px;
}

input[type="checkbox"]:checked:after {
  position: absolute;
  content: '';
  left: 3px;
  bottom: 2px;
  height: 6px;
  border-left: 2px solid white;
  transform: rotate(-45deg);
  border-radius: 2px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.custom-radio {
  display: block;
  width: 150px;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio span{
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #666666;

}

.custom-radio input:checked ~ span {
  background-color: white;
  border-color:  #8DBF4C;

}

.custom-radio input:disabled ~ span {
  background: #ccc none !important;
  border-color: #eee !important;
  cursor: default !important;
}

.custom-radio span:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ span:after {
  display: block;
}

.custom-radio span:after {
  top: 1px;
  left: 1px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #8DBF4C;
}

#export-dialog {
  #select-campaigns {
    cursor: pointer;
    overflow: auto;
    option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  select {
    border: 0.5px solid grey;
  }

}

#import-dialog,
#export-dialog {
  input[type="text"],
  input[type="text"]:focus{
    border: 1px solid gray;
  }
}

a{
  outline: none !important;
}

#import-dialog-option-group {
  label {
    display: inline-block;
  }
  #import-dialog-importgroup-combobox {
    margin-left: 0;
  }
}

