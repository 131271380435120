/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

/* Global Styles */

html,
body {
	height: 100%;
}

body {
	/*padding-top: 100px;*/
}

body[data-target=".navbar-fixed-top"] {
	padding-top: 50px; /* Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
}

/* The some style for the form elements*/

/* Home Page Carousel */

.lv-authorization-options {
	padding-top: 15px;
	text-shadow: 0 2px 5px #666;
}

.lv-logo {
	width: 188px;
	height: 50px;
	background: url("../../images/logivations/logivations-logo-no-map.png") no-repeat;
	background-position: center;
	background-size: contain;
}

.time-study-header-capitalize a{
	text-transform: capitalize!important;
}

.time-study-header-capitalize a{
	text-transform: capitalize!important;
}
/* navbar */
.navbar-default {
	background: #1e552c url("../../images/bg.png")!important;
	-ms-transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-o-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
	-webkit-box-shadow: 0 8px 6px -6px #4d4d4d;
	-moz-box-shadow: 0 8px 6px -6px #4d4d4d;
	box-shadow: 0 8px 6px -6px #4d4d4d;
	border-bottom: 1px solid #55694D;
}
/* Title */
.navbar-default .navbar-brand {
	padding: 0px;
	color: rgba(256, 256, 256, .6);
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: #fff;
}
/* Link */
.navbar-default .navbar-nav > li > a {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	color: rgba(256, 256, 256, .6);
	margin-right: 15px;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #fff;
	border-bottom: 0 solid transparent;
	background-color: transparent !important;
	-webkit-box-shadow: inset 0 -3px 0 0 #FFF;
	-moz-box-shadow: inset 0 -3px 0 0 #FFF;
	box-shadow: inset 0 -3px 0 0 #FFF;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: #fff;
	background-color: rgba(256, 256, 256, .3);
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.navbar-default .navbar-nav > .dropdown > a {
	background-color: transparent!important;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	color: #fff;
	background-color: rgba(256, 256, 256, .3)!important;
}
/* Caret */
.navbar-default .navbar-nav > .dropdown > a .caret {
	border-top-color: rgba(256, 256, 256, .6);
	border-bottom-color: rgba(256, 256, 256, .6);
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
	border-top-color: #fff;
	border-bottom-color: #fff;
}
.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
	border-top-color: #fff;
	border-bottom-color: #fff;
}

.btn-primary {
	color: #fff;
	background-color: #7cb854;
	border-color: #5d8d49;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	color: #fff;
	background-color: #5d8d49;
	border-color: #436734;
}

.btn-primary[disabled] {
	background-color: #888;
	border-color: #666;
}

.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active {
	background-color: #7cb854;
	border-color: #5d8d49;
}

.browser-unsupported-holder,
.change-language,
.user-confirmed-holder,
.user-invalid-confirmation-holder,
#enter-login-table,
#enter-answer-table{
	margin: 30px 0;
}

@media only screen and (max-width: 768px) {
	.browser-unsupported-holder,
	.user-confirmed-holder,
	.user-invalid-confirmation-holder,
	#enter-login-table,
	#enter-answer-table,
	.select-language-holder {
		margin-top: -20px!important;
	}
}

.user-confirmed-holder a,
.user-invalid-confirmation-holder a {
	margin: 20px 0;
}

#enter-login-table button{
	padding: 4px 25px;
}

#enter-login-table .form-inline,
#enter-answer-table .form-inline {
	padding: 20px 15px;
}

#w2mo-sign-up-header-link {
	color: #fff;
	font-weight: 800;
}

.intro-header {
	background-color: #808080;
	background: no-repeat center center;
	background-attachment: scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	padding-bottom: 50px;
}

.intro-header .site-heading,
.intro-header .post-heading,
.intro-header .page-heading {
	padding: 100px 0 50px;
	color: white;
}

@media only screen and (min-width: 768px) {
	.navbar {
		/*padding: 20px 0;*/
		-webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
		-moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
		transition: background .5s ease-in-out, padding .5s ease-in-out;
	}

	.top-nav-collapse {
		padding: 0;
	}
}

@media only screen and (min-width: 1024px) {
	.intro-header .site-heading,
	.intro-header .post-heading,
	.intro-header .page-heading {
		padding: 120px 0 60px 0;
	}
}

.intro-header .site-heading,
.intro-header .page-heading {
	text-align: left;
}

.intro-header .site-heading h1,
.intro-header .page-heading h1 {
	margin-top: 0;
	font-size: 50px;
}

.intro-header .site-heading .subheading,
.intro-header .page-heading .subheading {
	font-size: 24px;
	line-height: 1.1;
	display: block;
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 300;
	margin: 10px 0 0;
}

.subheading.second-subheading{
	color: #7da771;
}

@media only screen and (min-width: 768px) {
	.intro-header .site-heading h1,
	.intro-header .page-heading h1 {
		font-size: 60px;
	}
}

.intro-header .post-heading h1 {
	font-size: 35px;
}

.intro-header .post-heading .subheading,
.intro-header .post-heading .meta {
	line-height: 1.1;
	display: block;
}

.intro-header .post-heading .subheading {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 24px;
	margin: 10px 0 30px;
	font-weight: 600;
}

.intro-header .post-heading .meta {
	font-family: 'Lora', 'Times New Roman', serif;
	font-style: italic;
	font-weight: 300;
	font-size: 20px;
}

.intro-header .post-heading .meta a {
	color: white;
}

@media only screen and (min-width: 768px) {
	.intro-header .post-heading h1 {
		font-size: 55px;
	}

	.intro-header .post-heading .subheading {
		font-size: 30px;
	}
}

.login-form-container {
	background: rgba(255, 255, 255, 0.4);
	padding: 0;
}
.eventbox-holder {
	padding: 15px 30px;
	width: 100%;
	background: rgba(60, 60, 60, 0.45);
	margin-right: -15px;
	max-width: 300px;
	float: right;
}
.eventbox-holder p {
	color: #fff;
	font-size: 16px;
	text-shadow: 0 2px 5px #666;
}
.form-inline {
	padding-top: 70px;
}
.form-inline .input-group > .form-control {
	border: none;
}
#sign-in-form .eventbox-holder a {
	padding: 0 10px;
	background: #8dbf4c;
	width: 100%;
	display: block;
	text-align: center;
	margin-bottom: 15px;
	line-height: 43px;
	height: 43px;
	font-size: 1.1em;
	font-weight: normal;
	box-shadow: 0 2px 7px rgba(102, 102, 102, 0.5);
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}
#sign-in-form .eventbox-holder a:hover {
	color: #fff;
	background: #5d8d49;
}
.w2mo-feature {
	padding: 50px 0;
}

.w2mo-feature-text {
	margin-top: 0;
	font-size: 1.15em;
}

.lv-link, .lv-link:visited, .lv-link:focus {
	color: #5d8d49;
	font-weight: normal;
	padding: 10px 0;
	text-decoration: none;
}
.lv-link:hover {
	color: rgba(93, 141, 73, 0.6);
	text-decoration: none;
}
.w2mo-feature .lv-link {
	color: #fff!important;
	background: #8dbf4c;
	padding: 0 10px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	max-width: 200px;
	width: 100%;
	font-weight: normal;
	text-align: center;
	border-radius: 2px;
	margin-top: 10px;
	box-shadow: 0 4px 10px #f7f7f7;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}
.w2mo-feature .lv-link:hover {
	color: #fff;
	background: #5d8d49;
}
.w2mo-sign-up {
	background-image: url("../../images/logivations/forklift-bg.jpg");
	background-color: #7cb854;
	color: #fff;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	padding: 90px 0;
}

.post-preview > a {
	color: #404040;
}

.post-preview > a:hover,
.post-preview > a:focus {
	text-decoration: none;
	color: #0085a1;
}

.post-preview > a > .post-title {
	font-size: 30px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.post-preview > a > .post-subtitle {
	margin: 0;
	font-weight: 300;
	margin-bottom: 10px;
}

.post-preview > .post-meta {
	color: #808080;
	font-size: 18px;
	font-style: italic;
	margin-top: 0;
}

.post-preview > .post-meta > a {
	text-decoration: none;
	color: #404040;
}

.post-preview > .post-meta > a:hover,
.post-preview > .post-meta > a:focus {
	color: #0085a1;
	text-decoration: underline;
}

@media only screen and (min-width: 768px) {
	.post-preview > a > .post-title {
		font-size: 36px;
	}
}

.section-heading {
	font-size: 36px;
	margin-top: 60px;
	font-weight: 700;
}

.caption {
	text-align: center;
	font-size: 14px;
	padding: 10px;
	font-style: italic;
	margin: 0;
	display: block;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

section {
	padding: 50px 0;
}

.w2mo-get-app {
	background: #7cb854;
}

.section-box-shadow {
	-webkit-box-shadow: 0 0 10px #999;
	-moz-box-shadow: 0 0 10px #999;
	box-shadow: 0 0 10px #999;
}

.text-shadow-green {
	text-shadow: #1E552C 0 0 3px;
}

.ui-dialog-titlebar-close {
	display: none;
}
#w2mo-features h2 {
	font-weight: 600;
	color: #444;
	font-size: 35px;
}
.w2mo-feature h3.lv-subtitle {
	margin-top: 0;
	font-size: 26px;
}
h3.lv-subtitle {
	line-height: 1.2;
	font-size: 21px;
	color: #5d8d49;
	padding-bottom: 8px;
	margin-bottom: 25px;
	-webkit-box-shadow: inset 0 -1px 0 0 rgba(93, 141, 73, 0.4);
	-moz-box-shadow: inset 0 -1px 0 0 rgba(93, 141, 73, 0.4);
	box-shadow: inset 0 -1px 0 0 rgba(93, 141, 73, 0.4);
}
.panel {
	margin-top: 100px;
}
footer {
	background-color: #fff;
	padding: 50px 0 0 0;
}
.error-page-footer {
	background-color: #fff;
	padding: 50px 0 0 0;
	width: 100%;
	height: auto;
	position: absolute;
	text-align: center;
	bottom: 0;
	padding-bottom: 10px;
}

footer a, footer a:visited, footer a:focus {
	color: #999;
	display: block;
	text-decoration: none;
}

footer a:hover {
	color: #666;
	text-decoration: none;
}

.copyright {
	margin-top: 40px;
}

.ui-dialog-titlebar-close {
	display: none;
}

.w2mo-button-transition:not([disabled]) {
	-ms-transition: all .2s ease-in-out .1s;
	-moz-transition: all .2s ease-in-out .1s;
	-webkit-transition: all .2s ease-in-out .1s;
	-o-transition: all .2s ease-in-out .1s;
	transition: all .2s ease-in-out .1s;
}

.w2mo-flat-button {
	padding: 10px 20px;
	border: 2px solid #fff;
	border-radius: 3px;
	color: #fff;
	display: block;
	margin: 30px 0;
}

.w2mo-flat-button:hover,
.w2mo-flat-button:focus {
	background: #fff;
	text-decoration: none;
}

.fa-android {
	font-size: 2em;
	display: block;
	float: left;
	padding: 0 10px 20px 10px;
}

#w2mo-sign-up {
	font-size: 1.4em;
	background-color: rgba(256, 256, 256, .2);
}

#w2mo-sign-up:hover {
	border: 2px solid rgba(256, 256, 256, .2);
	color: #666;
	background-color: rgba(256, 256, 256, .8);
}

#w2mo-get-app:hover,
#w2mo-get-app:focus {
	color: #8ec255;
}

#trainings {
	margin: 20px 0;
}

#trainings h2 {
	margin: 30px 0;
}

input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, textarea:focus, .form-control:focus {
	-ms-box-shadow: 0 0 5px #7cb854;
	-moz-box-shadow: 0 0 5px #7cb854;
	-webkit-box-shadow: 0 0 5px #7cb854;
	-o-box-shadow: 0 0 5px #7cb854;
	box-shadow: 0 0 5px #7cb854;
	border: none;
}

#sign-in-form {
	background-image: url('../../images/logivations/welcome-bg.jpg');
	background-color: #959e97;
}

.lv-awards-achievements > div {
	margin-top: 60px;
}
.lv-awards-achievements > div > table {
	margin: 0 auto;
}
.lv-awards-achievements img {
	padding: 0 5px;
}

#sign-in-form .input-group-addon,
#enter-login-table .input-group-addon {
	background: #666;
	color: #e9e9e9;
	border: none;
}

#sign-in-form a,
#sign-in-form a:visited,
#sign-in-form a:focus {
	color: #fff;
	font-weight: 100;
	text-decoration: none;
}

.switch-view-mode-label {
	min-height: 14px;
	padding-left: 20px!important;
	font-weight: 600!important;
	color: #ffffff;
}

#sign-in-form a:hover {
	color: #7cb854;
	text-decoration: none;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 100px #fff inset;
}

.w2mo-sign-in.submitting,
.w2mo-sign-in:disabled {
	opacity: 0.7;
}

.w2mo-sign-in.submitting {
	cursor: wait;
}

.w2mo-sign-in.submitting:after {
	content: "...";
}

#sign-in-form button,
#sign-in-form button:focus{
	outline: none;
	background: #8dbf4c;
	color: #fff;
	border: none;
	padding: 10px;
	font-size: 1.1em;
	border-radius: 2px;
	box-shadow: 0 2px 10px #757575;
	width: 100%;
	height: 43px;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}
#sign-in-form button:hover {
	background: #5d8d49;
}
#sign-in-form button.w2mo-sign-in{
	padding: 10px;
	min-width: 200px;
}
button#back-button {
	background: #999;
	border: #666;
}

/* Modal Prompt Styles*/

.ui-dialog-titlebar {
	font-size: 0.7em;
	font-weight: normal;
}

.ui-dialog-titlebar * {
	cursor: move;
}

.ui-dialog-content .modal-prompt-icon, .modal-progress-box-icon {
	float: left;
	height: 40px;
	width: 40px;
	margin: 10px 12px 4px 0;
}

.modal-progress-box-icon {
	display: none;
}

.ui-dialog-content .modal-prompt-icon-error,
.popup-notifier-wrapper .modal-prompt-icon-error {
	background-image: url("../../images/prompt/error.png");
	background-size: contain;
}

.ui-dialog-content .modal-prompt-icon-info,
.popup-notifier-wrapper .modal-prompt-icon-info {
	background-image: url("../../images/prompt/info.png");
	background-size: contain;
}

.ui-dialog-content .modal-prompt-icon-warning,
.popup-notifier-wrapper .modal-prompt-icon-warning {
	background-image: url("../../images/prompt/warning.png");
	background-size: contain;
}

.ui-dialog-content .modal-prompt-icon-question {
	background-image: url("../../images/prompt/question.png");
}

.ui-dialog-content .modal-prompt-icon-success,
.popup-notifier-wrapper .modal-prompt-icon-success {
	background-image: url("../../images/prompt/success.png");
	background-size: contain;
}

.ui-dialog-content .modal-prompt-text {
	margin: 10px 0 0 60px;
}

.ui-dialog-content .modal-help-text {
	margin: 10px 0 0 10px;
}

.ui-dialog-content .online-help {
	color: #690;
	font-weight: bolder;
	margin-top: 12px;
	position: absolute;
	left: 0;
}

.ui-dialog-content .online-help:hover {
	color: #8b0;
}

.ui-dialog-content .modal-progress-box-indicator {
	height: 20px;
	width: 100%;
}

.ui-dialog .ui-dialog-buttonpane button,
.ui-dialog-content .modal-prompt-text,
.ui-dialog-content .modal-help-text,
.ui-dialog-content .modal-progress-box-text,
.ui-dialog-content .modal-progress-box-logger,
.ui-dialog table {
	font-size: 11px;
}

.ui-dialog table th,
.ui-dialog table td {
	border-bottom: 1px solid #eee;
	text-align: left;
	vertical-align: top;
}

.ui-dialog-content .modal-progress-box-text {
	margin-top: 12px;
	max-height: 300px;
	overflow-y: auto;
	width: 370px;
	word-wrap: break-word;
}

/* End Modal Prompt Styles*/

/* Style for browser.unsupported.jsp */

.browser-icon {
	height: 48px;
	width: 48px;
	position: relative;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center center;
	text-align: center;
}

.browser-icon:after {
	content: "\00a0";
}

.browser-firefox {
	background-image: url('../../images/browsers/firefox.png');
	_background-image: none;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader (src='images/browsers/firefox.png', sizingMethod='crop');
}

.browser-chrome {
	background-image: url('../../images/browsers/chrome.png');
	_background-image: none;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader (src='images/browsers/chrome.png', sizingMethod='crop');
}

.browser-safari {
	background-image: url('../../images/browsers/safari.png');
	_background-image: none;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader (src='images/browsers/safari.png', sizingMethod='crop');
}

.browser-opera {
	background-image: url('../../images/browsers/opera.png');
	_background-image: none;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader (src='images/browsers/opera.png', sizingMethod='crop');
}

.browser-ie {
	background-image: url('../../images/browsers/ie.png');
	_background-image: none;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader (src='images/browsers/ie.png', sizingMethod='crop');
}

/* End style for browser.unsupported.jsp */
.to-top{
	margin-top:15px;
}
.control-label{
	font-size:12px;
	color:#000;
}

@media screen and (max-width: 1199px) {
	#sign-in-form button.w2mo-sign-in{
		min-width: 175px;
	}
	.form-group > .input-group {
		 max-width: 175px;
	}
}
@media screen and (max-width: 991px) {
	.form-inline {
		padding-top: 30px;
	}
	.eventbox-holder {
		max-width: inherit;
		float: none;
		margin: 30px 0;
	}
	.w2mo-feature h3.lv-subtitle {
		margin-top: 30px;
	}
	.form-group > .input-group {
		max-width: inherit;
	}
}
@media screen and (max-width: 768px) {
	#w2mo-features h2 {
		font-size: 31px;
	}
	.w2mo-feature h3.lv-subtitle {
		font-size: 21px;
	}
	#trainings h2,
	#sign-up h2{
		font-size: 24px;
	}

}
@media screen and (max-width: 375px) {
	.lv-awards-achievements td {
		display: block;
		padding: 10px 0;
		text-align: center;
	}
	#w2mo-features h2 {
		font-size: 28px;
		text-align: left;
	}
	#trainings h2,
	#sign-up h2{
		font-size: 21px;
	}
}