/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/
@import "../../common/styles/w2mo-bootstrap-dark-theme";
@import "../../common/styles/w2mo-common.less";
@import "../../common/styles/modal-dialog.less";
@import "~font-awesome/less/font-awesome.less";
@import "./subscriptions.less";
@import "./publish-annoucement.less";
@import "~css/authorization.css";
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: rgba(256, 256, 256, .3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#page-content {
  padding-top: 30px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;

  > .container-fluid {
    padding: 0 0 50px 0;
  }

  .container-fluid {
    margin-top: -20px;
  }

}

.footer-logo {
  margin: auto;
}

.copyright {
  margin: 20px;
  font-size: .8em;
  color: #888;
}

.no-resize-textarea {
  resize: none;
}

.login-fail {
  color: red;
}

.form-group {
  > .input-group {
    width: 100%;
  }
}

#country-dropdown + .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

#university-country-dropdown, #university-position-dropdown {
  width: 100%;

}

#university-country-input-group, #university-position-input-group {
  > .dropdown {
    width: 100%;
  }

  .dropdown-menu {
    max-height: 300px;
    width: 100%;
    overflow-y: auto;

    .dropdown + .input-group-btn {
      width: 100%;
    }
  }
}

.sign-up {

  .panel-heading {
    border: none;
    background-color: white;
    ol {
      background-color: white;
    }
  }

  .form-horizontal {
    padding: 15px;
    max-width: 1200px;
    margin: 0 auto;
  }

  small {
    color: #AAA;
  }

  #billingAddress {
    resize: vertical;
  }

  .sign-up-captcha {
    border: solid 1px gray;
    height: 80px;
    width: 150px;
  }

  #refresh-captcha-image {
    cursor: pointer;
  }

  #refresh-captcha-image:hover {
    outline: solid 1px #d3d3d3;
  }

  .breadcrumb {
    margin-bottom: 5px;
    opacity: .75;
    > li + li:before {
      content: " > ";
    }
  }

  &.panel.panel-default {
    margin-top: 0;
  }
}
#sign-in-form button.w2mo-sign-in {
  background: @brand-primary;
  color: #fff;
  border: none;
  padding: 10px 65px;
  font-size: 1.1em;
  border-radius: 2px;
  box-shadow: 0 2px 10px #757575;
  width: 100%;
  height: 43px;
  &:hover {
    background: #5d8d49;
  }
}
.login-form-container {
  .form-group {
    margin-right: 5px;
  }
  .input-group {
    box-shadow: 0 2px 10px #757575;
  }
  .form-control {
    height: 43px;
    padding: 10px;
  }
  .lv-authorization-options a {
    padding-right: 15px;
  }
}
.w2mo-get-app {
  position: relative;
  h2 {
    margin: 0;
    font-size: 1.4em;
    padding: 0 5px;
  }
  .fa-android {
    font-size: 1.5em;
    display: inline-block;
    float: left;
    padding: 7px 25px;
    margin-top: -12px;
  }
  .w2mo-flat-button {
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(123, 122, 122, 0.25);
    font-size: 1em;
    padding: 20px;
    line-height: 1em;
  }
}
#sign-up.w2mo-sign-up {
  position: relative;
  z-index: 100;
  background-image: url("../images/lno-intra.png");
  .w2mo-flat-button {
    box-shadow: 0 2px 10px 0 rgba(35, 35, 35, 0.9);
    padding: 20px;
    border-radius: 2px;
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 19, 19, 0.59);
    z-index: -1;
  }
  a {
    font-size: 1.4em;
    line-height: 1.1em;
  }
}
.w2mo-feature {
  h3.lv-subtitle {
    color: @gray;
  }
  .section-box-shadow {
    box-shadow: 0 3px 20px 0 rgba(14, 14, 14, 0.18);
  }
  .lv-link, .lv-link:visited, .lv-link:focus {
    color: @brand-primary;
  }
}
.help-block.subscription-term {
  margin-top: 0;
  margin-bottom: 0;
}
.page-footer {
  padding: 0;
  width: 100%;
   img.img-responsive{
     display: inline-block;
   }
}
@media screen and (max-width: 637px) {
  .w2mo-get-app {
    .w2mo-flat-button {
      padding: 15px 5px;
    }
    h2 {
      font-size: 1em;
    }
    .fa-android {
      padding: 7px;
    }
  }
  .w2mo-sign-up {
    .w2mo-flat-button {
      padding: 15px 5px;
    }
    a {
      font-size: 1em;
    }
  }
  #sign-up.w2mo-sign-up {
    padding: 15px 0;
    a {
      font-size: 1em;
    }
  }
  #trainings {
    margin: 0;
    padding: 15px 0;
  }
}
@media screen and (min-width: 990px) {
  .account-footer {
    position: fixed;
    bottom: 0;
  }
}
@media screen and (max-width: 760px) {
  #user-data-parameters {
    max-height: initial;
    overflow: auto;
  }
  .form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}

#valid-confirmation, #invalid-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  i.fa-info-circle {
    margin-right: 5px;
  }
}
#phone-number-input-group {
  .column-phone-input {
    display: flex;
    .elem-with-btn {
      .country-list-btn {
        &:active {
          background-color: grey;
        }
      }
      .panel {
        margin: 0;
        position: absolute;
        z-index: 10000;
        width: 330px;
        list-style: none;
        padding-left: 5px;
        .form-control {
          .input-sm {
            width: 336px;
            margin-left: -17px;
            margin-top: -7px;
            border-radius: 2px;
            height: 32px;
          }
        }
      }
      .country-code-select {
        max-height: 250px;
        width: 330px;
        overflow: auto;
        overflow-y: scroll;
        #country-code-select, #country-name-select {
          list-style: none;
        }
      }
    }
    #phoneNumber {
      height: 35px;
    }
  }
}