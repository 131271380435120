/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/
.wmd-panel {
  min-width: 500px;
  width: 100%;
}

#wmd-button-bar {
  width: 100%;
}

#wmd-input {
  overflow-y: auto;
  margin-top: 5px;
  border: 1px solid;
  height: 100%;
  width: 100%;
}

#wmd-preview {
  min-height: 380px;
  width: 100%;
}

#wmd-button-row {
  padding: 0;
  position: relative;
  top: -20px;
}

.wmd-spacer {
  display: inline-block;
  height: 20px;
  list-style: none;
  margin-left: 14px;
  position: absolute;
  width: 1px;
}

.wmd-button {
  color: #8dbf4c;
  display: inline-block;
  height: 20px;
  list-style: none;
  margin-left: 5px;
  margin-right: 5px;
  position: absolute;
  width: 20px;
  font-size: 19px;
}

.wmd-button:hover {
  color: #658c32;
  background-color: #f1f1f1;
}

.wmd-button > a {
  display: inline-block;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  position: absolute;
  width: 20px;
}

#wmd-button-bar #wmd-bold-button {
  left: 0;
}

#wmd-button-bar #wmd-italic-button {
  left: 25px;
}

#wmd-button-bar #wmd-link-button {
  left: 55px;
}

#wmd-button-bar #wmd-quote-button {
  left: 80px;
  font-size: 23px;
}

#wmd-button-bar #wmd-code-button {
  left: 110px;
}

#wmd-button-bar #wmd-image-button {
  left: 140px;
}

#wmd-button-bar #wmd-olist-button {
  left: 170px;
}

#wmd-button-bar #wmd-ulist-button {
  left: 200px;
}

#wmd-button-bar #wmd-heading-button {
  left: 225px;
}

#wmd-button-bar #wmd-hr-button {
  left: 250px;
}

#wmd-button-bar #wmd-spacer3 {
  left: 300px;
}

#wmd-button-bar #wmd-undo-button {
  left: 275px;
}

#wmd-button-bar #wmd-redo-button {
  left: 300px;
}

#wmd-button-bar #wmd-help-button {
  right: 0;
}

.wmd-prompt-background {
}

.wmd-prompt-dialog {
  border: 1px solid;
}

.wmd-prompt-dialog > div {
  font-family: arial, helvetica, sans-serif;
  font-size: 0.8em;
}

.wmd-prompt-dialog > form > input[type="text"] {
  border: 1px solid;
}

.wmd-prompt-dialog > form > input[type="button"] {
  border: 1px solid;
  font-family: Trebuchet MS, helvetica, sans-serif;
  font-size: 0.8em;
  font-weight: bold;
}

/* Heritage hacks begin */

#w2mo-help-button {
  display: none;
}

/* Themes */

#wmd-preview {
  background-color: #f7f7f7;
  border: solid 1px #a9a9a9;
}

.help-area {
  background-color: #f7f7f7;
  border: solid 1px #b9b9b9;
  min-height: 830px;
}

.help-area text {
  display: block;
  color: #666;
  font-weight: bolder;
  padding: 5px;
  margin: 10px 0;
  line-height: 1;
  text-align: left;
  font-size: 14px;
}

.help-area text:hover {
  color: #8dbf4c;;
}

.help-area p {
  font-family: monospace;
  font-size: 14px;
  margin: 2px 0 8px 10px;
}

p .space {
  background-color: #e7e77f;
  margin: 0 1px;
}

#publish, #send-email {
  margin-top: 3px;
}

#wmd-redo-button {
  transform: scaleX(-1);
}

.publish-announcer-title {
  font-size: 28px;
  position: absolute;
  left: 145px;
  top: -5px;
  color: #f5f5f5;
}

.dropdown-text{
  font-size: 14px;
  height: 0;
  overflow: hidden;
  transition: all .6s;
}
.dropdown-active{
  height: 120px;
  transition: all .6s;
}

.announcement-text ul, .announcement ul {
  list-style-image: url("../images/lv-list-point.png");
}
.glyphicon-header:before {
  position: relative;
  bottom: 2px;
}
#modal-message-aria {
  font-size: 16px;
}
.dropdown-item {
  cursor: pointer;
}
