/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./resources/fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./resources/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./resources/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./resources/fonts/OpenSans/OpenSans-Bold.ttf');
}
