/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

.loader-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 20px;
  }

  .spinner-border {
    width: 100px;
    height: 100px;
    color: #8DBF4C !important;
  }
}
