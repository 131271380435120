/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/


.modal {

  > .modal-dialog {
    @media (min-width: 768px) {
      width: 650px;
    }
    &.info {
      > .modal-content > .modal-header {
        color: @btn-info-color;
        background: @brand-info;
        border-color: @btn-info-border;
        .border-top-radius(@border-radius-base);
      }
    }
    &.success {
      > .modal-content > .modal-header {
        color: @btn-success-color;
        background: @brand-success;
        border-color: @btn-success-border;
        .border-top-radius(@border-radius-base);
      }
    }
    &.warning {
      > .modal-content > .modal-header {
        color: @btn-warning-color;
        background: @brand-warning;
        border-color: @btn-warning-border;
        .border-top-radius(@border-radius-base);
      }
    }
    &.danger {
      & .modal-body {
        white-space: break-spaces;
      }

      > .modal-content > .modal-header {
        color: @btn-danger-color;
        background: @brand-danger;
        border-color: @btn-danger-border;
        .border-top-radius(@border-radius-base);
      }
    }
  }

}

.form-horizontal {
  .control-label {
    padding: 3px 0 0 25px;
    text-align: left;
  }

  .checkbox {
    padding-top: 3px;
  }
}
