/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

@import "~bootstrap/less/bootstrap.less";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-datetime/css/react-datetime.css";

@font-face {
  font-family: "Noto Sans";
  src: url(resources/fonts/Noto_Sans/NotoSans-Regular.ttf);
}

//variables
@brand-primary:                             #8dbf4c;
@font-family-base:                         'Noto Sans', sans-serif;
@font-size-base:                            15px;
@text-color:                                #666;
@body-bg:                                   #fff;
@inverse-text-color:                        #fff;
@inverse-body-bg:                           #666;
@link-color:                                @brand-primary;
@link-hover-decoration:                     none;

@input-border-focus:                        @brand-primary;

@navbar-height:                             60px;
@navbar-default-color:                      #fff;
@navbar-default-bg:                         #666;
@light-grey:                                #f1f1f1;
@grey:                                      #afafaf;

@navbar-default-link-color:                @navbar-default-color;
@navbar-default-link-hover-color:          @navbar-default-color;
@navbar-default-link-hover-bg:             darken(@navbar-default-bg, 6.5%);
@navbar-default-link-active-color:         @navbar-default-color;
@navbar-default-link-active-bg:            darken(@navbar-default-bg, 6.5%);

@panel-primary-border:                      #ccc;

@border-radius-base:                        2px;
@brand-success:                             @brand-primary;
@brand-info:                                @brand-primary;

@modal-lg:                                  650px;
@modal-md:                                  450px;
@modal-sm:                                  300px;


/*Mixins*/
.box-shadow(@arguments) {
  -webkit-box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  box-shadow: @arguments;
}

.linear-gradient(@firstArgument, @secondArgument, @thirdArgument) {
  background: -webkit-linear-gradient(@firstArgument, @secondArgument, @thirdArgument);
  background: -moz-linear-gradient(@firstArgument, @secondArgument, @thirdArgument);
  background: -o-linear-gradient(@firstArgument, @secondArgument, @thirdArgument);
  background: -ms-linear-gradient(@firstArgument, @secondArgument, @thirdArgument);
  background: linear-gradient(@firstArgument, @secondArgument, @thirdArgument);
}

.flex-display(@display: flex) {
  display: ~"-webkit-@{display}";
  display: ~"-ms-@{display}box"; // IE10 uses -ms-flexbox
  display: ~"-ms-@{display}"; // IE11
  display: @display;
}

.flex-wrap(@wrap: nowrap) {
  -webkit-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}

.background-clip(@argument: padding-box) {
  -moz-background-clip: @argument;
  -webkit-background-clip: @argument;
  background-clip: @argument;
}

.border-radius(@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.transition(@duration:0.2s, @ease:ease-out) {
  -webkit-transition: all @duration @ease;
  -moz-transition: all @duration @ease;
  -o-transition: all @duration @ease;
  transition: all @duration @ease;
}

.opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
}

.decrease-calc(@prop; @val) {
    @{prop}: calc(~'100% - @{val}');
    @{prop}: -moz-calc(~'100% -@{val}');
    @{prop}: -webkit-calc(~'100% - @{val}');
    @{prop}: -o-calc(~'100% -@{val}');
}

.transform (@string: rotate(-45deg)) {
  /* transform *///
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
  transform: @string;
}
//navbar.less
.navbar-default {
  border-color: darken(@navbar-default-bg, 10%);
  .box-shadow(0 0 5px @grey);
  .navbar-nav {
    > li > a {
      &:hover,
      &:focus {
        outline: 0;
      }
    }
    & > .open > a {
      & :hover {
        background-color: darken(@navbar-default-color, 10%);
        & when (@navbar-default-bg = white) {
          background-color: darken(@navbar-default-bg, 10%);
        }
      }
    }
  }
  .dropdown-menu {
    background-color: white;
    color: @navbar-default-bg;
    & when (@navbar-default-bg = white) {
      color: @navbar-default-color;
    }
    & > li > a {
      padding: 5px 20px;
      &:hover,
      &:focus {
        outline: 0;
      }
    }
    & > li > a:hover, > li > a:focus {
      background-color: darken(@navbar-default-color, 10%);
      & when (@navbar-default-bg = white) {
        background-color: darken(@navbar-default-bg, 10%);
      }
    }
  }
}
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-top: -2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}

.caret {
  border-top: 4px dashed;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-bottom: 4px dashed;
}


.collapse.height {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.collapsing.width {
  width: 0!important;
  -webkit-transition-property: width;
  -o-transition-property: width;
  transition-property: width;
}

.collapse.width {
  height: auto;
  //position: relative;
  -webkit-transition: width 0.35s ease;
  -moz-transition: width 0.35s ease;
  -o-transition: width 0.35s ease;
  transition: width 0.35s ease;
}

.list-group {
  .list-group-item-primary {
    background-color: @brand-primary;
    color: @panel-primary-text;
    &:hover, &:focus {
      background-color: @brand-primary;
      color: @panel-primary-text;
      text-decoration: underline;
    }
  }
}

.modal {
  > .modal-dialog {
    > .modal-content {
      > .modal-header {
        background: @brand-primary;
        color: @inverse-text-color;
      }
      .modal-footer {
        border: none;
      }
      .twoStepCalibration-header {
        background: #fff;
      }
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.btn-primary {
  background-color: @brand-primary;
  border-color: #adadad;
  .box-shadow(0 3px 5px rgba(0,0,0,.125));
}
p.form-control-static {
  padding-top: 0;
  padding-bottom: 0;
}